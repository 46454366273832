<template>
    <div class="top-main-menu-row">

        <!--------- BEGIN WDIVING LOGO LEFT SIDE (all screens) --------->

        <router-link
            :to="$helper.routerLinkGet( this, 'home' )"
            custom
            v-slot="{ href, navigate }"
        >
            <a
                :href="href"
                @click="navigate"
                class="navbar-brand"
            >
                <img src="../../assets/images/layout/w-dive-logo_100x104.png"
                     alt="„W-DIVING“ @ Kalafati Bay MYKONOS - GREECE SINCE 1993 Kalafati Dive Center"
                     width="24" height="24" class="d-inline-block align-text-top">
                <span class="text">W-Diving</span>
            </a>
        </router-link>

        <!--------- END WDIVING LOGO LEFT SIDE (all screens) --------->
        <!--------- BEGIN MAIN MENU HORIZONTAL (big screens) --------->

        <n-menu
            v-if="!navbarIsCollapsed"
            :class="currentLocale"
            v-model:value="menuActive"
            :mode="'horizontal'"
            :collapsed-width="48"
            :options="menuOptions"
            :default-value="'/'"
        />

        <!--------- END MAIN MENU HORIZONTAL (big screens) --------->
        <!--------- BEGIN TOP BUTTONS RIGHT SIDE (all screens) --------->

        <div class="btn-group">
            <n-button
                v-if="navbarIsCollapsed"
                class="me-2"
                type="tertiary"
                ghost
                size="small"
                @click.prevent.stop="showCollapsedMenu = !showCollapsedMenu; showSmallScreenLangMenu = false"
                ref="collapseBtn1"
            >
                <n-icon :size="18">
                    <menu-collapsed-icon v-if="!showCollapsedMenu" />
                    <close-icon v-else />
                </n-icon>
            </n-button>

            <!--------- BEGIN LANGUAGE MENU DROPDOWN (big screens) --------->

            <n-dropdown
                v-if="!navbarIsCollapsed"
                trigger="click"
                :options="localeOptions"
                :show-arrow="true"
                :render-icon="renderLocaleIcon"
                @select="handleChangeLocale"
            >
                <n-button
                    ghost
                    type="tertiary"
                    :size="'small'"
                >
                    <n-icon>
                        <change-language-icon />
                    </n-icon>
                </n-button>
            </n-dropdown>

            <!--------- END LANGUAGE MENU DROPDOWN (big screens) --------->
            <!--------- BEGIN LANGUAGE MENU BUTTON (small screens) --------->

            <n-button
                v-if="navbarIsCollapsed"
                ghost
                type="tertiary"
                :size="'small'"
                @click="showSmallScreenLangMenu = !showSmallScreenLangMenu; showCollapsedMenu = false"
            >
                <n-icon>
                    <change-language-icon v-if="!showSmallScreenLangMenu" />
                    <close-icon v-else />
                </n-icon>
            </n-button>

            <!--------- END LANGUAGE MENU BUTTON (small screens) --------->
        </div>

        <!--------- END TOP BUTTONS RIGHT SIDE (all screens) --------->

    </div>

    <div class="top-main-menu-col" v-if="navbarIsCollapsed">

        <!--------- BEGIN MAIN MENU VERTICAL (small screens) --------->

        <transition
            enter-active-class="enter-active"
            leave-active-class="leave-active"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <n-menu
                v-if="showCollapsedMenu"
                :class="currentLocale"
                v-model:value="menuActive"
                :mode="'vertical'"
                :options="menuOptions"
                :default-value="'/'"
            />
        </transition>

        <!--------- END MAIN MENU VERTICAL (small screens) --------->
        <!--------- BEGIN LANGUAGE MENU VERTICAL (small screens) --------->

        <transition
            enter-active-class="enter-active"
            leave-active-class="leave-active"
            @before-enter="beforeEnter"
            @enter="enter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @leave="leave"
            @after-leave="afterLeave"
        >
            <n-menu
                v-if="showSmallScreenLangMenu"
                :class="[navbarIsCollapsed ? 'small-screen-locale-menu' : '']"
                :value="smallScreenLangMenuActive"
                @update:value="handleChangeLocale"
                :mode="'vertical'"
                :options="localeOptions"
                :default-value="'en'"
                :render-icon="renderLocaleIcon"
            />
        </transition>

        <!--------- END LANGUAGE MENU VERTICAL (small screens) --------->
    </div>
</template>

<script>
import { NButton, NIcon } from 'naive-ui'
import { Globe as ChangeLanguageIcon, Bars as MenuCollapsedIcon } from '@vicons/fa'
import { Close as CloseIcon } from '@vicons/ionicons5'
import { mapGetters, mapState } from 'vuex'
import { localeNames } from '../../translation'
import _ from 'lodash'
import { h } from 'vue'
// https://github.com/ubaldop/vue-country-flag
import CountryFlag from 'vue-country-flag-next'
import RouterLinkItem from "./items/RouterLinkItem"

export default {
    components: {
        NButton, NIcon, ChangeLanguageIcon, MenuCollapsedIcon, CloseIcon
    },
    data() {
        return {
            // Is overwritten by setLocaleToDocument()
            currentLocale: this.$i18n.fallbackLocale,
            localeNames: localeNames,
            menuActive: '',
            navbarIsShown: false,
            renderLocaleIcon: ( option ) => {
                const country = option.key !== 'en' ? option.key : 'us'

                return h( "div", {
                    style: { position: 'relative', width: '30px', height: '20px' }, class: "ms-2 me-1"
                }, [
                    h( CountryFlag, {
                        country: country, size: 'normal', class: "ms-1", style: {
                            width: '53px', height: '35px', transform: 'scale(0.4)',
                            margin: '0em -0.9em -0.6em -0.7em', position: 'absolute',
                            left: '-21px', top: '-8px'
                        }
                    } )
                ] )
            },
            showCollapsedMenu: false,
            showSmallScreenLangMenu: false
        }
    },
    computed: {
        ...mapGetters( [ 'getSmallScreenMode' ] ),
        ...mapState( [ "config", "navigation" ] ),
        useDarkTheme() {
            return this.config.useDarkTheme;
        },
        localeOptions() {
            let options = []

            _.forEach( this.localeNames, ( name, locale ) => {
                options.push( { label: name, key: locale, disabled: this.currentLocale === locale } )
            } )

            return options
        },
        menuOptions() {
            let options = []

            _.forEach( this.menuStructure, item => {
                options.push( this.renderLink( item, 1 ) )
            } )

            return options
        },
        menuStructure() {
            return this.navigation.main
        },
        navbarIsCollapsed() {
            return this.getSmallScreenMode
        },
        smallScreenLangMenuActive() {
            return this.currentLocale
        }
    },
    methods: {
        getLocaleFromUrl() {
            if ( this.$route.query.lang && this.$i18n.availableLocales.includes( this.$route.query.lang ) )
                this.setLocaleToDocument( this.$route.query.lang )
            else
                this.setLocaleToDocument( this.translateBrowserLocaleString() )
        },
        handleChangeLocale( locale ) {
            this.setLocaleToDocument( locale )
            this.setLocaleToUrl( locale )

            if ( this.navbarIsCollapsed )
                this.showSmallScreenLangMenu = false
        },
        setLocaleToDocument( locale ) {
            if ( locale === undefined )
                locale = this.currentLocale
            this.currentLocale = locale
            let doc = document.querySelector( 'html' )
            doc.setAttribute( 'locale', locale )
            doc.setAttribute( 'lang', locale )
            this.$i18n.locale = locale
            this.setLocaleToUrl( locale )
        },
        setLocaleToUrl( locale ) {
            if ( !this.$route.query.lang || this.$route.query.lang !== locale )
                this.$router.replace( { query: { lang: locale } } )
        },
        // DOKU: https://www.naiveui.com/en-US/os-theme/components/menu#Menu-Props
        renderLink( item, level ) {
            const label = () => h(
                RouterLinkItem, {
                    toName: item.name,
                    title: item.title,
                    level: level,
                    noClick: !!item.hasSubs,
                    onClickedOn: () => {
                        this.showCollapsedMenu = false
                    }
                }
            )

            const op = {
                label: label,
                key: item.name
            }

            if ( item.hasSubs ) {
                op.children = []
                const id = level + 1

                _.forEach( item.hasSubs, child => {
                    op.children.push( this.renderLink( child, id ) )
                } )
            }

            return op
        },
        translateBrowserLocaleString() {
            let locale = ''
            const browserLang = navigator.language || navigator.userLanguage
            const availableLocales = this.$i18n.availableLocales

            locale = browserLang.length > 2 ? browserLang.substr( 0, 2 ) : browserLang
            locale = availableLocales.includes( locale.toLowerCase() ) ? locale : this.$i18n.fallbackLocale

            return locale.toLowerCase()
        },
        /*
        --------------- Menu Animtions ----------------
        https://stackoverflow.com/questions/64379093/animate-v-if-with-slide-down-up-transition-vue-js-2
         */
        /**
         * @param {HTMLElement} element
         */
        beforeEnter( element ) {
            requestAnimationFrame( () => {
                if ( !element.style.height ) {
                    element.style.height = '0px';
                }

                element.style.display = null;
            } );
        },
        /**
         * @param {HTMLElement} element
         */
        enter( element ) {
            requestAnimationFrame( () => {
                requestAnimationFrame( () => {
                    element.style.height = `${ element.scrollHeight }px`;
                } );
            } );
        },
        /**
         * @param {HTMLElement} element
         */
        afterEnter( element ) {
            element.style.height = null;
        },
        /**
         * @param {HTMLElement} element
         */
        beforeLeave( element ) {
            requestAnimationFrame( () => {
                if ( !element.style.height ) {
                    element.style.height = `${ element.offsetHeight }px`;
                }
            } );
        },
        /**
         * @param {HTMLElement} element
         */
        leave( element ) {
            requestAnimationFrame( () => {
                requestAnimationFrame( () => {
                    element.style.height = '0px';
                } );
            } );
        },
        /**
         * @param {HTMLElement} element
         */
        afterLeave( element ) {
            element.style.height = null;
        }
    },
    mounted() {
        this.getLocaleFromUrl()
    },
    watch: {
        '$route.query.lang': {
            handler: function ( lang ) {
                if ( lang )
                    this.setLocaleToDocument( lang )
                else
                    this.getLocaleFromUrl()
            },
            deep: true,
            immediate: true
        },
        currentLocale( newValue ) {
            this.setLocaleToDocument( newValue )
        },
        navbarIsCollapsed( isCollapsed ) {
            if ( isCollapsed )
                this.showCollapsedMenu = false
        }
    }
}
</script>

<style scoped>
.top-main-menu-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    font-weight: 500;
    position: relative;
}

:deep(.n-menu) {
    font-size: 24px;
    line-height: 1.5;
}

:deep(.n-menu.gr) {
    font-size: 20px;
    line-height: 1;
}

.navbar-brand {
    margin-right: 0;
    font-size: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--top-nav-link) !important;
}

.navbar-brand:hover {
    color: var(--page-text-blue-1) !important;
}

.navbar-brand .text {
    margin-left: 5px;
    padding-top: 5px;
}

button:focus, button:focus-visible {
    outline: 0;
    box-shadow: none;
}

:deep(.n-menu.n-menu--horizontal .n-menu-item-content) {
    padding: 0 10px;
}

.enter-active,
.leave-active {
    overflow: hidden;
    transition: height .5s ease-in-out;
}

.small-screen-locale-menu :deep(.n-menu-item-content__icon) {
    margin-right: 18px !important;
}

.small-screen-locale-menu :deep(.n-menu-item-content) {
    padding-left: 18px !important;
}
</style>
