import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

const HeaderSlider = () => import("../components/header/HeaderSlider")
const NoHeaderSlider = () => import("../components/header/NoHeaderSlider")
const AboutUs = () => import( "../pages/AboutUs")
const Contact = () => import( "../pages/Contact")
const ActivitiesDiscover = () => import( "../pages/ActivitiesDiscover")
const ActivitiesBoat = () => import( "../pages/ActivitiesBoat")
const ActivitiesPadi = () => import( "../pages/ActivitiesPadi")
const ActivitiesWreck = () => import( "../pages/ActivitiesWreck")
const ActivitiesPrivate = () => import( "../pages/ActivitiesPrivate")
const ActivitiesSnorkle = () => import( "../pages/ActivitiesSnorkle")
const Imprint = () => import("../pages/Imprint")
const Prices = () => import( "../pages/Prices")
const ShopLocation = () => import( "../pages/ShopLocation")
const ShopEquipment = () => import( "../pages/ShopEquipment")

const none = { template: '<div>NOCH NICHT GEMACHT!</div>' }
// DOKU: https://cli.vuejs.org/guide/mode-and-env.html
const isDevMode = process.env.NODE_ENV === 'development'

const router = createRouter( {
    // DOKU: https://router.vuejs.org/guide/essentials/history-mode.html
    history: isDevMode ? createWebHashHistory() : createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            components: {
                HeaderSlider: HeaderSlider,
                MainContent: AboutUs
            }
        },
        {
            path: '/activities',
            name: 'activities',
            children: [
                {
                    path: "discover",
                    name: "discover",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ActivitiesDiscover
                    }
                },
                {
                    path: "boat",
                    name: "boat",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ActivitiesBoat
                    }
                },
                {
                    path: "wreck",
                    name: "wreck",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ActivitiesWreck
                    }
                },
                {
                    path: "padi",
                    name: "padi",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ActivitiesPadi
                    }
                },
                {
                    path: "snorkle",
                    name: "snorkle", // Is also used in HeaderSlider !!
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ActivitiesSnorkle
                    }
                },
                {
                    path: "private",
                    name: "private",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ActivitiesPrivate
                    }
                },
            ]
        },
        {
            path: '/prices',
            name: 'prices',
            components: {
                HeaderSlider: HeaderSlider,
                MainContent: Prices
            }
        },
        {
            path: '/shop',
            name: 'shop',
            children: [
                {
                    path: "location",
                    name: "location",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ShopLocation
                    }
                },
                {
                    path: "equipment",
                    name: "equipment",
                    components: {
                        HeaderSlider: HeaderSlider,
                        MainContent: ShopEquipment
                    }
                }
            ]
        },
        {
            path: '/contact',
            name: 'contact',
            components: {
                HeaderSlider: HeaderSlider,
                MainContent: Contact
            }
        },
        {
            path: '/imprint',
            name: 'imprint',
            components: {
                HeaderSlider: NoHeaderSlider,
                MainContent: Imprint
            }
        },
        {
            path: '/none',
            name: 'none',
            components: {
                MainContent: none
            }
        },
        {
            path: '/*',
            components: {
                MainContent: none
            }
        },
    ]
} )

// router.beforeEach( ( to, from, next ) => {
//     if ( from.query.lang ) {
//         if ( to.path === from.path ) {
//             // This is a no-go via the documentation, but a bug in routing to identical routes
//             // strips query params, and this prevents that
//             return
//         }
//         next( {
//             path: to.path,
//             query: { lang: from.query.lang },
//         } )
//     }
//     next()
// } )

export default router